import React from 'react';
import {Helmet} from 'react-helmet';

const Rules = () => {

    return (
        <section className="section about-section">
            <Helmet><title>Rules</title></Helmet>
            <header className="tc ph4">
                <h1>
                  Contest and League Rules
                </h1>
            </header>
            <pre>
              <p>
                Contest and League results will be determined by the total points accumulated by each individual user
                based on the aggregate results of their in-game wagers during the specified period or term (“Game Set”)
                of the Contest or League. Participation in each Contest or League must be made only as specified in the
                Terms of Service. Failure to comply with these Terms of Service will result in disqualification and, if
                applicable, prize forfeiture.
              </p>

              <p>
                A &quot;Game Set&quot; is a set of games used for contests; each contest is tied to one Game Set. Contests tied to
                the same Game Set can be created at different times. Therefore, game policies are based on the timing
                of a Game Set being made available, not each individual contest.
              </p>

              <p>
              If the scheduled start time for a game changes after contests for a Game Set containing that game
              become available, the Drives Service will take measures to ensure the change is reflected on applicable
              Contest or League screens in a best efforts manner. In the event that a game is cancelled or postponed
              and rescheduled to a time within the original Game Set, the game will be included within the Game Set.
              </p>

              <p>
                The Game Set for NFL is defined as the timeframe between the scheduled start of the first game within
                the Game Set and 11:59pm ET on the first Tuesday after the last scheduled game within the Game Set.
                The Drives Service may choose to adjust the games include in a Game Set at their sole discretion to
                accommodate schedule changes to any time before 12:00am ET on the day after the Game Set is
                scheduled. Any games that are disabled will be indicated as such on the Drives Service.
              </p>

              <p>
                In the event of a game malfunction, all Game Sets with the affected match up will be cancelled and refunded.
              </p>

              <p>
                For Terms of Service see https://drives.football/terms-of-service
              </p>

              <p>
                APPLE AND GOOLGE ARE NOT SPONSORS NOR ARE INVOLVED IN ANY WAY IN DRIVES FOOTBALL
                CONTEST AND LEAGUES.
              </p>
            </pre>
        </section>
    );
}

export default Rules;