import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./assets/css/style.css";
import TagManager from "react-gtm-module";
import "./assets/scss/style.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const tagManagerArgs = {
  gtmId: "GTM-NHSKLHJ",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();
