import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Calculator = () => {
  const IFRAME_HOST_URL = process.env.REACT_APP_IFRAME_HOST_URL;

  const [parentScrollHeight, setParentScrollHeight] = useState(1000); // Initial height

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === IFRAME_HOST_URL) {
        if (
          event.data.type === "setScrollHeight" &&
          event.data.scrollHeight &&
          event.data.scrollHeight > parentScrollHeight
        ) {
          setParentScrollHeight(event.data.scrollHeight);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <Helmet>
        <title>DS calculator</title>
      </Helmet>
      <iframe
        title="drives-football-ds-calculator-screen"
        id="ds-calculator-iframe"
        scrolling="no"
        width={"100%"}
        height={parentScrollHeight}
        frameBorder={0}
        allowFullScreen={true}
        src={`${IFRAME_HOST_URL}/v1/ds-calculator?host_url=${window.location.origin}`}
      />
    </section>
  );
};

export default Calculator;
