import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/layout/Footer";
// import screenfull from "screenfull";
// import { MobileView } from "react-device-detect";
// import { BsArrowsFullscreen } from "react-icons/bs";

const Broadcast = () => {
  const IFRAME_HOST_URL = process.env.REACT_APP_IFRAME_HOST_URL;

  const [parentScrollHeight, setParentScrollHeight] = useState(1800); // Initial height

  // const [supportsPWA, setSupportsPWA] = useState(false);
  // const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      // setSupportsPWA(true);
      // setPromptInstall(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === IFRAME_HOST_URL) {
        if (event.data.type === "scrollToTop") {
          window.scrollTo(0, 0);
        } else if (
          event.data.type === "setScrollHeight" &&
          event.data.scrollHeight &&
          event.data.scrollHeight > parentScrollHeight
        ) {
          setParentScrollHeight(event.data.scrollHeight);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [fullScreen, setFullScreen] = useState(false);

  // const requestFullScreen = () => {
  //   if (screenfull.isEnabled) {
  //     screenfull.toggle();
  //     setFullScreen(!fullScreen);
  //   } else {
  //     alert("Please enable full screen permission");
  //   }
  // };

  // useEffect(() => {
  //   const handleFullScreenChange = () => {
  //     if (screenfull.isFullscreen) {
  //       setFullScreen(true);
  //     } else {
  //       setFullScreen(false);
  //     }
  //   };

  //   screenfull.on("change", handleFullScreenChange);

  //   return () => {
  //     screenfull.off("change", handleFullScreenChange);
  //   };
  // }, []);

  return (
    <section>
      <Helmet>
        <title>Broadcast</title>
      </Helmet>
      <iframe
        title="drives-football-broadcast-screen"
        id="broadcast-iframe"
        scrolling="no"
        width={"100%"}
        height={parentScrollHeight}
        frameBorder={0}
        allowFullScreen={true}
        allow="autoplay;fullscreen;microphone"
        src={`${IFRAME_HOST_URL}/v1/app-broadcast?host_url=${window.location.origin}&api_key=IDMo1LCgelu3MACEatcLYDYqmRrzZn1Z`}
      />
      <Footer />
    </section>
  );
};

export default Broadcast;
