import React from "react";
import { Helmet } from "react-helmet";
import DataDeleteStep1 from "../assets/images/dataDel1.jpeg";
import DataDeleteStep2 from "../assets/images/dataDel2.jpeg";
import DataDeleteStep3 from "../assets/images/dataDel3.jpeg";
import DataDeleteStep4 from "../assets/images/dataDel4.jpeg";
import DataDeleteStep5 from "../assets/images/dataDel5.jpeg";
import DataDeleteStep6 from "../assets/images/dataDel6.jpeg";

const DataDeletion = () => {
  return (
    <section className="section about-section">
      <Helmet>
        <title>Data Deletion</title>
      </Helmet>
      <header className="tc ph4">
        <h1>Data Deletion Instructions</h1>
      </header>
      <pre>
        <p>
          Leaving us? Sorry to see you go. To delete your data, follow these
          steps.
        </p>
        <ol>
          <li>
            <p>Open the Drives Football app</p>
            <img
              src={DataDeleteStep1}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
          <li>
            <p>Go to the home screen</p>
            <img
              src={DataDeleteStep2}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
          <li>
            <p>
              Go to your account by tapping the top section of the Home screen
            </p>
            <img
              src={DataDeleteStep3}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
          <li>
            <p>Once on the Account screen, tap Settings</p>
            <img
              src={DataDeleteStep4}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
          <li>
            <p>Then open the Delete Account section and tap Delete User Data</p>
            <img
              src={DataDeleteStep5}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
          <li>
            <p>Confirm data deletion</p>
            <img
              src={DataDeleteStep6}
              alt="dataDel1"
              width={330}
              height={700}
            />
          </li>
        </ol>
      </pre>
    </section>
  );
};

export default DataDeletion;
