import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Play = () => {
  const IFRAME_HOST_URL = process.env.REACT_APP_IFRAME_HOST_URL;

  const [parentScrollHeight, setParentScrollHeight] = useState(2800); // Initial height of 2800px

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === IFRAME_HOST_URL) {
        if (event.data.type === "scrollToTop") {
          window.scrollTo(0, 0);
        } else if (event.data.type === "setScrollHeight") {
          setParentScrollHeight(event.data.scrollHeight);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="section about-section">
      <Helmet>
        <title>Play Now</title>
      </Helmet>
      <iframe
        title="drives-football-game-screen"
        id="play-iframe"
        scrolling="no"
        width={"100%"}
        height={parentScrollHeight}
        className="mt-4"
        frameBorder={0}
        src={`${IFRAME_HOST_URL}/v1/play-now?host_url=${window.location.origin}&api_key=IDMo1LCgelu3MACEatcLYDYqmRrzZn1Z`}
      />
    </section>
  );
};

export default Play;
