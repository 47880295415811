import React from 'react';
import {Helmet} from 'react-helmet';
import Image from '../components/elements/Image';
import AboutHero from '../components/sections/AboutHero';
import './../assets/css/About.css'
import 'react-multi-carousel/lib/styles.css';

const About = () => {

    return (
        
        <section className="section about-section">
            <Helmet><title>About</title></Helmet>
            <header className="tc ph4">
                <h1>
                    About Drives
                </h1>
            </header>
            <div className="tc ph4">
                <p className="text">
                Drives Football originated from a simple statement, “I’ll bet they score”. It is a common
                declaration that can be heard throughout the stadium on game day, in bars, or at a gathering of
                friends watching the big game. Determining the fair value of that bet and crafting a simple game
                around its premise has been Drives’ mission since that fateful statement.
                </p>
                <p className="text">
                The foundation of Drives is the Drives Score, the likely scoring outcome of
                an offensive possession for a given play. As an offense moves down the field,
                the Drives Score rises as the prospects of scoring increase. Setbacks in its
                progress will drop the Drives Score.
                </p>
                <div className="flexBox">
                  <div className="flexImageBox">
                    <Image
                      src={require('../assets/images/slidedrives.gif')}
                      alt="Features split 01"
                      width={600}
                      height={960} />
                  </div>
                  <div className="flexItem">
                    <p className="text">
                    The probable outcome of a drive is dependent on many factors,
                    including field position, down and distance, the strength of the offense and defense, current
                    score, time remaining in the game, weather, etc. Since the likelihood of scoring fluctuates with
                    each offensive play, a new Drives Score is calculated at the end of the play to reflect the
                    offense’s revised scoring prospects. Crucial or “big” plays cause the greatest change in the
                    Drives Score.
                    </p>  
                    <p className="text">
                    Drives creates “betting” markets around the Drives Score that allow players to Buy or Sell the
                    prospects of the current possession during any “dead ball” period. A Buyer of a drive gains the
                    difference between the price they paid for a drive and its ultimate outcome. For example, if the
                    player purchased a drive for 2.40 or 240 Drives Points* he would: lose 240 Drives Points if the
                    drive did not result in a score; receive 60 Drives Points if the drive resulted in a field goal (3
                    points); and receive 460 Drives Points if the drive resulted in a touchdown and a conventional
                    one-point conversion (7 points). If a drive resulted in a “pick-six” and the typical one-point
                    conversion, the buyer would lose 940 Drives Points (-240 for the purchase of the drive and -700
                    for the TD against).
                    [*100 is the default Drives Point multiplier]
                    </p>
                    <p className="text">
                    A seller of a drive gains the difference between the price they sold the drive for and its ultimate
                    outcome. For example, if the player sold a drive for 2.40 or 240 Drives Points they would: gain
                    240 Drives Points if the drive resulted in no points; lose 60 Drives Points if the drive resulted in
                    a field goal (3 points); lose 460 Drives Points if the drive resulted in a touchdown and a
                    conventional one-point conversion. (7 points). If a drive resulted in a “pick-six” and the typical
                    one-point conversion the seller would gain 940 Drives Points (240 for the sale of the drive and
                    700 for the TD).
                    </p>
                    <p className="text">
                    Players can also “take profits” during a given drive. For example, if they
                    purchased a drive for 240 Drives Points and the offense successfully
                    drove into the red zone, the player could sell the drive at the higher
                    market prices (e.g. 4.60) if they doubted the team’s ability to score
                    a touchdown.
                    </p>
                  </div>
                </div>
                
            </div>
            <header className="tc ph4 pt-24">
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    Styles of Play
                </h2>
            </header>
            <div className="gridBox">
              <div className="block">
                <h3>Buy/Sell and Hold</h3>
                A player buys or sells a drive prior to the kickoff or its first snap and holds the position until the drive concludes.
              </div>
              <div className="block">
                <h3>Active Trader</h3>
                A player that actively buys/sells at any point during a drive to gain a profit. An example would be a strategy to buy a team early in a drive and sell as they enter the red zone capitalizing on the player’s belief that the team has a weak red zone offense.
              </div>
              <div className="block">
                <h3>Crucial Plays</h3>
                A player might want to only play crucial downs and distances which offer extreme Drives Score volatility – e.g. a “third and eight” from the opponents 45-yard line. A successful conversion could return 4x the points used to purchase the drive.
              </div>
              <div className="block">
                <h3>Red Zones Play</h3>
                A player may prefer to watch and play Red Zone action. Play in this section of the field can be more volatile and influence the game’s ultimate outcome.
              </div>
            </div>
            <AboutHero />
        </section>
    );
}

export default About;