import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [email, setEmail] = useState('');
  const refContainer = useRef(null);

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("HI");
    refContainer.current.click();
  }

  useEffect(() => {
    console.log(refContainer.current);
  }, [refContainer]);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="ctaWrapper">
        <div className="container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h3 className="m-0">
                Subscribe to our newsletter.
              </h3>
            </div>
            <div className="cta-action">
              <form action="https://football.us4.list-manage.com/subscribe/post" method="POST" >
                <input type="hidden" name="u" value="10b437c8548f81ddce695b52f"></input>
                <input type="hidden" name="id" value="12dd66d725"></input>

                <label htmlFor='MERGE0'>
                  <input style={{ display: "none" }} type="email" name="EMAIL" id="MERGE0" readOnly={true} value={email}></input>
                </label>

                <input style={{ display: "none" }} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" ref={refContainer} />
              </form>

              <Input type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your E-mail Address" onChange={(e) => setEmail(e.target.value)} value={email}>
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path onClick={handleSubmit} d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                </svg>
              </Input>

            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;