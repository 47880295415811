import { Helmet } from "react-helmet";
import React from "react";

const Support = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "2rem auto",
    padding: "20px",
    // backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  };

  const listStyle = {
    listStyle: "none",
    padding: "0",
  };

  const itemStyle = {
    margin: "1rem 0",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "8px",
  };

  const questionStyle = {
    fontWeight: "bold",
  };

  const FAQ = [
    {
      id: 1,
      ques: `Why doesn't [this] work?`,
      ans: "Our devs will be working to fix any problems identified during early season play. Please share any issues you may encounter to support@drives.football.",
    },
    {
      id: 2,
      ques: "How do I favorite a team?",
      ans: "On the Play Now > Start Time tab scroll to find your team and tap the star next to its logo.",
    },
    {
      id: 3,
      ques: "Can I play multiple games at once?",
      ans: "Yes! You can tap the pin icon for a particular Game Screen and it will be saved as a tab at the top of the screen. Users can quickly tab between games for rapid, multigame play.",
    },
    {
      id: 4,
      ques: 'Can I Sell a drive "short" - i.e. Sell it without having bought it previously?',
      ans: "Yes",
    },
    {
      id: 5,
      ques: "Why did that Drives Score look wonky?",
      ans: "The Drives Score model and its underlying dataset are extremely complex. We may have issues in calculating 100% accurate scores initially. Consider them arbitrage opportunities or bug bounties, they will not be available for long.",
    },
    {
      id: 6,
      ques: `Why can't we play for real money?`,
      ans: "State gaming regulations are complex, as is assuring fair play. We are working on it. If you want to help Share the app or Upgrade from the side menu.",
    },
    {
      id: 7,
      ques: "What is the Broadcast Delay?",
      ans: "Nationally televised transmissions are delayed 6 to 8 seconds to allow for content moderation - e.g. cutting away from an unsightly streaker. The Broadcast Delay enables users to sync app play updates with the game broadcast they are viewing. The typical pause in play is usually well over 20 seconds, the Broadcast Delay should have little to no effect on gameplay.",
    },
  ];

  return (
    <section className="section about-section">
      <Helmet>
        <title>Support</title>
      </Helmet>

      <header className="tc ph4">
        <h1>Frequently Asked Questions</h1>
      </header>
      <pre>
        <div style={containerStyle}>
          <ul style={listStyle}>
            {FAQ.map((value, index) => (
              <li key={index} style={itemStyle}>
                <div style={questionStyle}>Q: {value.ques}</div>
                <div>A: {value.ans}</div>
              </li>
            ))}
          </ul>
        </div>

        <p>
          If you have any other questions, please feel free to reach us at{" "}
          <a
            style={{
              textDecoration: "underline",
            }}
            href="mailto:support@drives.football"
          >
            support@drives.football
          </a>
        </p>
      </pre>
    </section>
  );
};

export default Support;
