import React from 'react';
import {Helmet} from 'react-helmet';

const DrivesScore = () => {

    return (
        <section className="section about-section">
            <Helmet><title>Drives Score</title></Helmet>
            <header className="tc ph4">
                <h1>
                    The Drives Score
                </h1>
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    An Introduction
                </h2>
            </header>
            <div className="tc ph4">
                <p className="text">
                The Drives Score is the probabilistic or expected outcome of an offensive possession. Drives typically yield either zero (0), three (3) or seven (7) points. For each play, the associated probabilities of all outcomes, including atypical results, can be calculated and summed into a single number, the Drives Score. For example, if the likelihood of the three typical outcomes of a drive are equal, the resulting Drives Score would be 3.33 (0 x 33.3% + 3 x 33.3% + 7 x 33.3%).
                </p>
                <p className="text">
                The expected outcome of a drive is dependent on many factors, including field position, the strength of the offense, the strength of the defense, score differential, time remaining in the game, weather, etc. Since the likely outcome of a drive changes after each offensive play, the Drives Score fluctuates with each play. Crucial or “big” plays cause the greatest change in the probable outcome of a drive.
                </p>
            </div>

            <header className="tc ph4">
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    Drives Score Added (DSA)
                </h2>
            </header>
            <div className="tc ph4">
                <p className="text">
                Calculates an offensive skill player’s contribution to the progression of an offensive drive and team scoring. DSA distinguishes between insignificant player involvement and meaningful, situational contributions. As an example, four yards gained on a first down add little value to scoring probability, while the same four yards gained on a third down that result in a first down have a dramatic influence on a drive’s result. While this difference seems intuitive, DSA quantifies the differences and can be summed to assess a player’s overall value to the offense for a game or season.
                </p>
                <p className="text">
                DSA is related to Expect Points Added (EPA) but proves to be a more robust calculation. DSA’s simplicity, its focus on the result of a single drive, enables it to handle complex game situations – e.g. end-of-game strategy. EPA, as commonly calculated, incorporates complexity to provide a broader statistic, but its limitations hinder its ultimate value. Drives is developing an EPA calculation that builds upon its existing DSA model. A Drives EPA will be both time and situation sensitive, rectifying two major flaws of current EPA models.
                </p>
            </div>

            <header className="tc ph4">
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    Drives Score Added - Clutch (DSAC)
                </h2>
            </header>
            <div className="tc ph4">
                <p className="text">
                    DSAC incorporates game situation components – e.g. time remaining and score differential – to the existing DSA model to generate a “clutch” measure for offensive skill players. DSAC quantifies the disparity in value between the seminal plays that help determine the outcome of a game and meaningless garbage-time achievements. Like DSA, DSAC can be summed to gauge a player’s game, season, or career accomplishments.
                </p>
            </div>

            <header className="tc ph4">
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    Drives Score Expected (DSE)
                </h2>
            </header>
            <div className="tc ph4">
                <p className="text">
                    Compares league-wide Drives Score for the start of each drive versus the accumulated results of those drives. DSE can be used to compare offenses and defenses against the league average. DSE improves upon points-per-drive by incorporating field position and game situation.             
                </p>
            </div>

            <header className="tc ph4">
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    Drives Score Expected - Team (DSET)
                </h2>
            </header>
            <div className="tc ph4">
                <p className="text">
                    Compares team-specific Drives Score for the start of each drive versus the accumulated results of those drives. DSET can be used to compare starting and backup quarterback play.
                </p>
            </div>
        </section>
    );
}

export default DrivesScore;