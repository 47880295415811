import React from 'react';
import '../assets/css/FullScreen.css'
import {Helmet} from 'react-helmet';

const ComingSoon = () => {
    return (
        <section className="vh-100 items-center bigCenter">
            <Helmet><title>Coming Soon</title></Helmet>
            <h1 className="ph5">Coming Soon.</h1>
        </section>
        
    );
}

export default ComingSoon;