import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Route from "./utils/Route";
import LayoutDefault from "./layouts/LayoutDefault";
import Home from "./views/Home";
import About from "./views/About";
import DrivesScore from "./views/DrivesScore";
import ComingSoon from "./views/ComingSoon";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Terms from "./views/Terms";
import Rules from "./views/Rules";
import DataDeletion from "./views/DataDeletion";
import Support from "./views/Support";
import Play from "./views/Play";
import Broadcast from "./views/Broadcast";
import Demo from "./views/Demo";
import Calculator from "./views/Calculator";
import TV from "./views/TV";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <LayoutDefault>
              <Home />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <LayoutDefault>
              <About />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/drives-score"
          element={
            <LayoutDefault>
              <DrivesScore />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/coming-soon"
          element={
            <LayoutDefault>
              <ComingSoon />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <LayoutDefault>
              <PrivacyPolicy />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/terms-of-service"
          element={
            <LayoutDefault>
              <Terms />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/support"
          element={
            <LayoutDefault>
              <Support />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/play"
          element={
            <LayoutDefault>
              <Play />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/data-deletion"
          element={
            <LayoutDefault>
              <DataDeletion />
            </LayoutDefault>
          }
        />
        <Route exact path="/tv" element={<TV />} />
        <Route
          exact
          path="/broadcast"
          element={<Broadcast />} // No LayoutDefault for the "/broadcast" route
        />
        <Route
          exact
          path="/calc"
          element={
            <LayoutDefault>
              <Calculator />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/rules"
          element={
            <LayoutDefault>
              <Rules />
            </LayoutDefault>
          }
        />
        <Route
          exact
          path="/demo"
          element={
            <LayoutDefault>
              <Demo />
            </LayoutDefault>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
