import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <section className="section about-section">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <header className="tc ph4">
        <h1>Privacy Policy</h1>
      </header>
      <pre>
        <p>
          Probabilistic Inc. built the Drives Football app as a Freemium app.
          This SERVICE is provided by Probabilistic Inc. at no cost and is
          intended for use as is.
        </p>
        <p>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.{" "}
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <p>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at Drives Football unless
          otherwise defined in this Privacy Policy.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            Information Collection and Use
          </h2>
        </header>
        <p>Our app collects the following Primary Account Information:</p>
        <ul>
          <li>Name</li>
          <li>Username</li>
          <li>Phone number</li>
          <li>Age</li>
          <li>US State</li>
          <li>Email address(Optional)</li>
          <li>Profile Picture(Optional)</li>
        </ul>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            How We Use Your Primary Data
          </h2>
        </header>
        <p>
          We use this information to create and manage user accounts, to provide
          personalized services, and to communicate with users, including but
          not limited to the following:
        </p>

        <ul>
          <li>providing you with our products and Services</li>
          <li>processing and responding to inquiries</li>
          <li>personalizing your use of the Services</li>
          <li>maintaining records</li>
          <li>non-marketing or administrative purposes</li>
          <li>investigating and protecting the integrity of Drives gameplay</li>
          <li>to personalize marketing and advertising content</li>
        </ul>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">Retention</h2>
        </header>

        <p>
          We will retain your personal information for as long as necessary to
          fulfill these purposes and as necessary to comply with our legal
          obligations, resolve disputes, and enforce our policies. You can opt
          out of data collection by uninstalling our app. Questions regarding
          Primary Data usage can be sent to{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="mailto:support@drives.football"
          >
            support@drives.football
          </a>
          . If you have installed a mobile app and you wish to stop receiving
          push notifications, you can change the settings either on your mobile
          device or through the app.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">Other Data</h2>
        </header>

        <p>
          Our app may also collect other types of data, such as your location,
          device type, and app usage. We use this data to improve our app, to
          personalize services, and to target advertising.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">Log Data</h2>
        </header>
        <p>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
          <br />
          To delete your data -{" "}
          <a
            style={{
              textDecoration: "underline",
            }}
            href="https://drives.football/data-deletion"
          >
            https://drives.football/data-deletion
          </a>
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">Cookies</h2>
        </header>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory. This Service does not use these “cookies” explicitly. However,
          the app may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the option to
          either accept or refuse these cookies and know when a cookie is being
          sent to your device. If you choose to refuse our cookies, you may not
          be able to use some portions of this Service.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            Service Providers
          </h2>
        </header>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons: to facilitate our Service; to provide the Service
          on our behalf; to perform Service-related services; or to assist us in
          analyzing how our Service is used. We want to inform users of this
          Service that these third parties have access to your Personal
          Information. The reason is to perform the tasks assigned to them on
          our behalf. However, they are obligated not to disclose or use the
          information for any other purpose.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">Security</h2>
        </header>
        <p>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            Links to Other Sites
          </h2>
        </header>
        <p>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            Children's Privacy
          </h2>
        </header>
        <p>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>

        <header className="tc ph4">
          <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
            Changes to This Privacy Policy
          </h2>
        </header>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. This policy is effective as of 2023-09-21.
        </p>
      </pre>
    </section>
  );
};

export default PrivacyPolicy;
