import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Drives Unique Features',
    paragraph: 'Providing a unique experience to our players'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 hero-title">
                  Buy and Sell Drives
                  </h3>
                <p className="m-0">
                Drives creates “betting” markets around the Drives Score that allow players to Buy or Sell the prospects of the current possession during any “dead ball” period. A Buyer of a drive gains the difference between the price they paid for a drive and its ultimate outcome.
                </p>
                <div className="spacer-12" data-reveal-delay="600">
                  <Button tag="a" color="dark" wideMobile href="/drives-score">
                    Learn More
                  </Button>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/graphics/1.png')}
                  alt="Features split 01" />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 hero-title">
                  The Drives Score
                  </h3>
                <p className="m-0">
                  The typical outcomes of an offensive possession are zero (0),
                  three (3), or seven (7). Drives weights the likely outcomes to
                  determine the Drives Score - the “fair value” for any given
                  play. Drives then calculates Buy or Sell prices for any given
                  point of a drive.
                </p>
                <div className="spacer-12" data-reveal-delay="600">
                  <Button tag="a" color="dark" wideMobile href="/drives-score">
                    Learn More
                  </Button>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/graphics/2.png')}
                  alt="Features split 02" />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 hero-title">
                  Community Building
                  </h3>
                <p className="m-0">
                  <ul>
                    <li><strong>In-game chat channels</strong> - GET READY TO TALK SMACK WITH YOUR FRIENDS!</li>
                    <li><strong>Leaderboards</strong> touting big winners, top traders, and wins of the day</li>
                    <li><strong>BCast</strong> – a Twitter-like platform for sharing game and game play insights (coming soon)</li>
                    <li><strong>Piggy-back trading</strong> - trade like the pros (coming soon)</li>
                    <li><strong>“Share Drop”</strong> (i.e. equity allocation) for top users and influencers</li>
                  </ul>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/graphics/3.png')}
                  alt="Features split 02" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;