import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/layout/Footer";

const TV = () => {
  const IFRAME_HOST_URL = process.env.REACT_APP_IFRAME_HOST_URL;

  const [parentScrollHeight, setParentScrollHeight] = useState(700); // Initial height of 1000px

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === IFRAME_HOST_URL) {
        if (event.data.type === "scrollToTop") {
          window.scrollTo(0, 0);
        } else if (event.data.type === "setScrollHeight") {
          setParentScrollHeight(event.data.scrollHeight);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <Helmet>
        <title>TV</title>
      </Helmet>
      <iframe
        title="drives-football-tv-screen"
        id="tv-iframe"
        scrolling="no"
        width={"100%"}
        height={parentScrollHeight}
        frameBorder={0}
        allowFullScreen={true}
        allow="autoplay;fullscreen"
        src={`${IFRAME_HOST_URL}/v1/tv?host_url=${window.location.origin}&api_key=IDMo1LCgelu3MACEatcLYDYqmRrzZn1Z`}
      />
      <Footer />
    </section>
  );
};

export default TV;
