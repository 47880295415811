import React from "react";
import "../../assets/css/FullScreen.css";
import Image from "../elements/Image";

const FullScreen = () => {
  return (
    <div className="fullscreen-section container">
      <section className="flex-ns items-center">
        <div className="tc tl-ns spacer-right spacer-bottom">
          <Image
            className="homedrives"
            src={require("./../../assets/images/homedrives.png")}
            alt="drives-logo"
          />

          {/* <h1 className="f3 f1-l fw2 mb3 mt4 mt0-ns">Play in a whole new way.</h1> */}
          <div className="spacer">
            <h2 className="lh-title">“I'll Bet They Score"</h2>
            <h3 className="lh-subtitle">
              You've said it, now you can play it.
            </h3>
          </div>

          <div className="download-buttons">
            <a
              href="https://apps.apple.com/us/app/id1521841406"
              className="dib grow ph3-right"
            >
              <Image
                src={require("./../../assets/images/apple-store-badge.png")}
                alt="appledownload"
                id="appledownload"
                width={150}
                height={150}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.drives.football"
              className="dib grow ph3-left"
            >
              <Image
                src={require("./../../assets/images/google-play-badge.png")}
                alt="googledownload"
                id="googledownload"
                width={200}
                height={200}
              />
            </a>
          </div>
        </div>
        <div>
          <Image
            className="mobile-screenshot"
            src={require("./../../assets/images/mobiles.png")}
            alt=""
          />
        </div>
      </section>
      <Image
        className="bg-circle"
        src={require("./../../assets/images/bg-circle.png")}
        alt="bg-circle"
      />
    </div>
  );
};

export default FullScreen;
